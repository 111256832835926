import React, { useState, useEffect } from "react";
import { Div, Text, ModuleContainer } from "../../styles/Common";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import { Card } from "../../components/Card";
import useGetPromoterQuestions from "./hooks/useGetPromoterQuestions";
import { ModalAnswerQuestion } from "./components/ModalAnswerQuestion";
import { getLabelFromValue } from "../../utilities/helpers";
import { PromoterQuestionStatus } from "../../utilities/generalOptions";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-left.svg";
export const PromoterQuestions = () => {
  const { t } = useTranslation("promoterQuestions");
  const navigate = useNavigate();

  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");

  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [showModalAnswer, setShowModalAnswer] = useState(false);

  const getuPromoterQuestions = useGetPromoterQuestions(projectId);
  const { data, isSuccess, isLoading } = getuPromoterQuestions;
  const [questionsList, setQuestionsList] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setQuestionsList(
        data?.data?.data.map((item) => ({
          ...item,
          status: item?.status
            ? getLabelFromValue(item.status, PromoterQuestionStatus)
            : "pending",
        }))
      );
    }
  }, [data, isSuccess]);

  const tableColumns = [
    {
      title: t("theme"),
      dataIndex: "topic",
      key: "topic",
      width: "12%",
    },
    {
      title: t("question"),
      dataIndex: "question",
      key: "question",
      width: "34%",
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: "12%",
      render: (value) => (
        <Div
          width="auto"
          height="36px"
          radius="20px"
          p="10px"
          background={stateColors(value).backgroundColor}
          style={{ color: stateColors(value).textColor }}
          align="center"
          justify="center"
        >
          {t(value)}
        </Div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setSelectedQuestion({});
    setShowModalAnswer(false);
  };

  const handleBack = () => {
    navigate("/projects");
  };

  const stateColors = (state) => {
    switch (state) {
      case "pending":
        return {
          textColor: theme.colors.yellow700,
          backgroundColor: theme.colors.yellow100,
        };
      case "derived":
        return {
          textColor: theme.colors.blue400,
          backgroundColor: theme.colors.blue200,
        };
      case "answered":
        return {
          textColor: theme.colors.green500,
          backgroundColor: theme.colors.greenLight,
        };

      default:
        return {
          textColor: theme.colors.black,
          backgroundColor: theme.colors.gray100,
        };
    }
  };

  return (
    <ModuleContainer padding="22px 50px 40px 50px" direction="column">
      <ModalAnswerQuestion
        showModal={showModalAnswer}
        handleCloseModal={handleCloseModal}
        selectedQuestion={selectedQuestion}
        projectId={projectId}
      />
      <Div m="0 0 21px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Div>
      <Card
        padding={"22px 29.5px 30px 29px"}
        height="max-content"
        width={"100%"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Div width="100%" align="center" justify="space-between" m="0 0 51px 0">
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.medium}
          >
            {t("manageQuestions")}
          </Text>
        </Div>
        <Div>
          <Table
            data={questionsList}
            shadow={"0px"}
            padding={"0px"}
            columns={tableColumns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setSelectedQuestion(record);
                setShowModalAnswer(true);
              },
              style: { cursor: "pointer" },
            })}
            locale={{
              emptyText: isLoading ? (
                <Div justify="center" gap="16px">
                  {[...Array(9)].map((item, idx) => (
                    <Skeleton
                      key={idx}
                      title={false}
                      paragraph={{
                        rows: 10,
                        width: "100%",
                      }}
                    />
                  ))}
                </Div>
              ) : (
                "No Data"
              ),
            }}
          />
        </Div>
      </Card>
    </ModuleContainer>
  );
};

PromoterQuestions.propTypes = {};
