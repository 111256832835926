import React from 'react';
import PropTypes from 'prop-types';
import { Col, Div, Row, Text } from '../../../styles/Common';
import { theme } from '../../../styles/theme';
import Drawer from '../../../components/Drawer';

const DrawerVariables = ({ visible, handleClose }) => {
  if (!visible) return null;

  const variables = [
    {
      key: "[PROJECT.PROJECTTOTALMW]",
      value: "Total peak power"
    },
    {
      key: "[PROJECT.PLANTSCOUNT]",
      value: "Number of plants"
    },
    {
      key: "[PROJECT.PROJECTNAME]",
      value: "Name of the project"
    },
    {
      key: "[PROJECT.MINRTBDATE]",
      value: "First estimated RtB date (format month/year)"
    },
    {
      key: "[PROJECT.MAXRTBDATE]",
      value: "Last estimated RtB date (month/year format)"
    },
    {
      key: "[PROJECT.CONSTRUCTIONDATE]",
      value: "Estimated construction start date (quarterly/yearly format)"
    },
    {
      key: "[PROJECT.MINCODDATE]",
      value: "First start-up date (month/year format)"
    },
    {
      key: "[PROJECT.MAXCODDATE]",
      value: "Last commissioning date (format trimstre/year)"
    },
    {
      key: "[PROJECT.CAPEX]",
      value: "Estimated total project cost (CAPEX)"
    },
    {
      key: "[PROJECT.PPAPERCENT]",
      value: "Percentage of electricity sold under PPAs"
    },
    {
      key: "[PROJECT.PPATENOR]",
      value: "Term PPA"
    },
    {
      key: "[PROJECT.ENERGYSALESSCHEMA]",
      value: "Energy sales scheme"
    },
    {
      key: "[PROJECT.PPAPRICE]",
      value: "Estimated price"
    },
    {
      key: "[DATA.SHAREHOLDER]",
      value: "Shareholder"
    },
    {
      key: "[DATA.ASSETTYPE]",
      value: "Asset type"
    },
    {
      key: "[FINANCMODEL.CREDITVALUEMW]",
      value: "Credit amount"
    },
    {
      key: "[FINANCMODEL.CREDITVALUE]",
      value: "Total Credit Amount"
    },
    {
      key: "[SOURCE.MINIPERNTENOR]",
      value: "Term financing - Long term"
    },
    {
      key: "[SOURCE.UNDERLYINGTENOR]",
      value: "Underliying tenor"
    },
    {
      key: "[SOURCE.INTEREST]",
      value: "Interest Type"
    },
    {
      key: "[SOURCE.AMORTSTRUCTURE]",
      value: "Amortization structure"
    },
    {
      key: "[SOURCE.PPATENORREQUIREMENT]",
      value: "PPA period - Duration"
    },
    {
      key: "[SOURCE.PPACOVERAGE]",
      value: "PPA Coverage %"
    },
    {
      key: "[SOURCE.DSCRFORPPA]",
      value: "Debt Coverage Ratio with PPA"
    },
    {
      key: "[SOURCE.DSCRFORMERCHANT]",
      value: "Debt Coverage Ratio for Merchant"
    },
    {
      key: "[SOURCE.PRODUCTIONSCENARIO]",
      value: "Production Scenario"
    },
    {
      key: "[SOURCE.PRICECURVESCENARIO]",
      value: "Scenario for price curve"
    },
    {
      key: "[SOURCE.HEDGE]",
      value: "Hedge"
    },
    {
      key: "[SOURCE.MAXIMUMLEVERAGE]",
      value: "Maximum allowable leverage over CAPEX"
    },
    {
      key: "[SOURCE.OPENINGFEE]",
      value: "Opening fee"
    },
    {
      key: "[SOURCE.SPREAD]",
      value: "Spread"
    },
    {
      key: "[SOURCE.CALLSCHEDULE]",
      value: "Call Schedule"
    },
    {
      key: "[SOURCE.COMMITMENTFEE]",
      value: "Commitment fee"
    }
  ];

  return (
    <Drawer
      title={"Variables"}
      open={visible}
      onClose={handleClose}
      width="600px"
      padding="0 20px 20px 20px"
    >
      <Div direction="column" gap="12px" m="20px 0 30px 0">
        {variables.map((variable, index) => (
          <Row key={index} gap="8px">
            <Col width="45%">
              <Text mt="0" size="14px" weight="600" color={theme.colors.green}>{variable.key}</Text>
            </Col>
            <Col width="55%">
              <Text mt="0" size="14px" weight="400" color={theme.colors.gray300}>{variable.value}</Text>
            </Col>
          </Row>
        ))}
      </Div>
    </Drawer>
  );
};

DrawerVariables.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DrawerVariables;