import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetTermsheetMaster = (type) => {
  const data = useQuery(
    ["getTermSheetMaster", type], 
    () => axiosClient.get(`/termsheetMaster/strategyType/${type}`),
    { enabled: !!type }
  );

  return data;
};
export default useGetTermsheetMaster;