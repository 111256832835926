import React, { useState } from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../../styles/Common";
import { Row, Col } from "antd";
import {
  CardInvestorProj,
  DashedLine,
  Divider,
  WrapperImage,
  StatusLabel,
} from "../styles";
import { theme } from "../../../../styles/theme";
import { CloseIcon, SunIcon, WindyIcon } from "../../../../assets/icons";
import { Link } from "../../../../components/Link";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import { dateFormat, formatNumber, formatToThousand } from "../../../../utilities/helpers";
import ModalDiscardProject from "./ModalDiscardProject";
import useSetActivityLog from "../../../InvestorProjectDetail/hooks/useSetActivityLog";
import toast from "react-hot-toast";

export const SaleProjects = ({ projectsData, returnImg, handleDetail }) => {
  const { t, i18n } = useTranslation("projectsTabsView");
  const locale = i18n.language;
  const setLog = useSetActivityLog();

  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDiscard = (project) => {
    const payload = {
      action: "intention-to-discard-project",
      projectId: project?._id,
    };

    setLog.reset();
    setLog.mutate(payload, {
      onSuccess: () => {
        setSelectedProject(project?.teaser?.name);
        setSelectedProjectId(project?._id);
        setShowModal(true);
      },
      onError: (err) => {
        toast.error(err?.response?.data?.result?.code?.message);
      },
    });
  };

  return (
    <Div direction="column" gap="24px">
      <ModalDiscardProject
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedProject={selectedProject}
        instanceType={"sale"}
        selectedProjectId={selectedProjectId}
      />
      {projectsData.map((ele, indx) => (
        <CardInvestorProj key={indx}>
          {Array.isArray(ele?.teaser?.strategiesSale) &&
            ele?.teaser?.strategiesSale[0]?.transactionSummary
              ?.closedTransaction && (
              <Row
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  marginBottom: "1px",
                  textAlign: "-webkit-right",
                }}
              >
                <Col md={24}>
                  <StatusLabel color={theme.colors.red500}>
                    <Text
                      color={theme.colors.white}
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.medium}
                    >
                      {t("projectClosed")}
                    </Text>
                  </StatusLabel>
                </Col>
              </Row>
            )}
          <WrapperImage
            backgImg={returnImg(
              ele?.projectType.type.name,
              Array.isArray(ele?.teaser?.strategiesSale) &&
              ele?.teaser?.strategiesSale[0]?.transactionSummary
                ?.closedTransaction
            )}
          >
            <Text
              color={theme.colors.white}
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.medium}
            >
              {ele?.teaser?.name}
            </Text>
            <Div align="center" m="3px 0 0 0">
              {ele?.projectType.type.name === "solar" ? (
                <SunIcon fill={theme.colors.yellow200} width={24} height={24} />
              ) : (
                <WindyIcon
                  fill={theme.colors.yellow200}
                  width={24}
                  height={24}
                />
              )}
              <Text color={theme.colors.yellow200}>
                {t(`${ele?.projectType.type.name}`)}
              </Text>
            </Div>
            <DashedLine />
            <Div justify="space-between">
              <Div direction="column">
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("size")}
                </Text>
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                >
                  {`${formatNumber(ele?.teaser?.teaserProjectSize, locale) || "-"
                    } MWp`}
                </Text>
              </Div>
              <Div direction="column">
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("price")}
                </Text>
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                  style={{marginRight: "10px"}}
                >
                  {ele?.teaser?.teaserTargetPrice && ele?.teaser?.teaserTargetPrice !== 0 ? (
                    <span>{`€ ${formatNumber(ele?.teaser?.teaserTargetPrice, locale)}`}</span>
                  ) : (
                    ele?.teaser?.strategiesSale && ele?.teaser?.strategiesSale[0] ? (
                      <span>{`€ ${formatToThousand(ele?.teaser?.strategiesSale[0].priceConsiderations.pricePerMWpFrom, locale, 1)} - € ${formatToThousand(ele?.teaser?.strategiesSale[0].priceConsiderations.pricePerMWpTo, locale, 1)}`}</span>
                    ) : (
                      <span>{t("openPrice")}</span>
                    )
                  )}
                </Text>
              </Div>
            </Div>
          </WrapperImage>
          <Div direction="column" p="20px 25.5px 22px 0" width="100%">
            <Div justify="space-between" align="center" width="100%" gap="2px">
              <Div direction="column" align="flex-start">
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("projectPhase")}:
                </Text>
                <Text
                  color={theme.colors.gray200}
                  size={theme.fonts.size.default}
                >
                  {ele?.teaser?.teaserProjectStatus || "-"}
                </Text>
              </Div>
              <Link
                onClick={() => {
                  if (
                    Array.isArray(ele?.teaser?.strategiesSale) &&
                    !ele?.teaser?.strategiesSale[0]?.transactionSummary
                      ?.closedTransaction
                  ) {
                    handleDiscard(ele);
                  }
                }}
                style={{
                  cursor:
                    Array.isArray(ele?.teaser?.strategiesSale) &&
                    ele?.teaser?.strategiesSale[0]?.transactionSummary
                      ?.closedTransaction &&
                    "not-allowed",
                }}
              >
                {t("discardProject")}
                <CloseIcon fill={theme.colors.green} />
              </Link>
            </Div>
            <Divider />
            <Div justify="space-between" align="center" width="100%" gap="2px">

              <Div direction="column" align="flex-start">
                {ele?.expectedClosingDate && (<>
                  <Text
                    color={
                      Array.isArray(ele?.teaser?.strategiesSale) &&
                        !ele?.teaser?.strategiesSale[0]?.transactionSummary
                          ?.closedTransaction
                        ? theme.colors.blue
                        : theme.colors.red500
                    }
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("closing")}:
                  </Text>
                  <Text
                    color={
                      Array.isArray(ele?.teaser?.strategiesSale) &&
                        !ele?.teaser?.strategiesSale[0]?.transactionSummary
                          ?.closedTransaction
                        ? theme.colors.gray200
                        : theme.colors.red500
                    }
                    size={theme.fonts.size.default}
                  >
                    {ele?.expectedClosingDate
                      ? dateFormat(ele?.expectedClosingDate)
                      : "-"}
                  </Text>
                </>)}
              </Div>
              <Button
                onClick={() => handleDetail(ele?._id)}
                disabled={
                  Array.isArray(ele?.teaser?.strategiesSale) &&
                  ele?.teaser?.strategiesSale[0]?.transactionSummary
                    ?.closedTransaction
                }
              >
                Acceso Inversión
              </Button>
            </Div>
          </Div>
        </CardInvestorProj>
      ))}
    </Div>
  );
};

SaleProjects.propTypes = {
  projectsData: PropTypes.array,
  returnImg: PropTypes.func,
  handleDetail: PropTypes.func,
};
