import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Text } from "../../../styles/Common";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { formatNumberOpex } from "../../../utilities/helpers";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";
import { LineType } from "../../../utilities/plantsOptions";

export const SaleOperation = ({
  plantsInfo,
  e,
  m,
  kv,
  handleSelect,
  setPlantsInfo,
  handleChangeFormatOpex
}) => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col md={8}>
          <Input
            id={"longitud"}
            label={t("lineLongDist")}
            suffix={<img alt="m" src={m} />}
            value={FormatNumber(plantsInfo.longitud, locale)}
            onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("typeOfLine")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={LineType}
            value={plantsInfo.typeLine}
            onChange={(event) => handleSelect(event, "typeLine")}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"tensionLine"}
            label={t("lineVoltage")}
            suffix={<img alt="kv" src={kv} />}
            value={FormatNumber(plantsInfo.tensionLine, locale)}
            onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
          />
        </Col>
      </Row>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mt="23px"
        mb="23.5px"
      >
        {t("plantOpex")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <Col md={8}>
          <Input
            id={"costesEstimadosOM"}
            label={t("o&mCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesEstimadosOM === "" ? "" : formatNumberOpex(plantsInfo.costesEstimadosOM, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"costesSeguros"}
            label={t("insuranceCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesSeguros === "" ? "" : formatNumberOpex(plantsInfo.costesSeguros, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"costesImpuestos"}
            label={t("taxes")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesImpuestos === "" ? "" : formatNumberOpex(plantsInfo.costesImpuestos, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"otrosGastos"}
            label={t("otherCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.otrosGastos === "" ? "" : formatNumberOpex(plantsInfo.otrosGastos, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
      </Row>
    </>
  );
};

SaleOperation.propTypes = {
  plantsInfo: PropTypes.object,
  e: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleSelect: PropTypes.func,
  setPlantsInfo: PropTypes.func,
  handleChangeFormatOpex: PropTypes.func,
};
