import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { Card } from "../../../components/Card";
import { Div, Row, Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { TextArea } from "../../../components/TextArea";
import { Collapse } from "../../../components/Collapse";
import { TermsheetItem } from "./termsheetItem";
import { Button } from "../../../components/Button";
import useTermsheetStore from "../../../core/store/termsheet-store";
import { useTranslation } from "react-i18next";
import DrawerVariables from "./DrawerVariables";
import DrawerEditItem from "./DrawerEditItem";
import languageIcon from "../../../assets/icons/language.svg";

export const ContentTermsheetMaster = ({ loading, onSave, saving }) => {
  const { t } = useTranslation('termsheetMaster');
  const { getTermsheetMaster, getDocuments, getId, editDocument, addItem } = useTermsheetStore();
  const [activeKey, setActiveKey] = useState('');
  const [isDrawerVariablesOpen, setIsDrawerVariablesOpen] = useState(false);
  const [isDrawerAddItemOpen, setIsDrawerAddItemOpen] = useState(false);
  const [lang, setLang] = useState('es');
  const [selectedSectionId, setSelectedSectionId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    editDocument(lang, { [name]: value });
  };

  const handleAddItem = (sectionId) => {
    setIsDrawerAddItemOpen(true);
    setSelectedSectionId(sectionId);
  }

  const closeDrawerAddItemOpen = () => {
    setIsDrawerAddItemOpen(false);
    setSelectedSectionId(null);
  }

  const addItemToSection = (item) => {
    addItem(lang, selectedSectionId, item);
    setIsDrawerAddItemOpen(false);
  }

  const handleSaveTermsheet = () => {
    const body = getTermsheetMaster();
    const id = getId();
    onSave(id, body);
  };

  const filteredDocument = getDocuments().find(doc => doc.lang === lang);

  const transformSectionsToPanels = (sections) => {
    if (!sections) return [];
    return sections.map((section, index) => ({
      key: index.toString(),
      header: section.title,
      panel: (
        <Div direction="column">
          <Div p="20px 0" width="100%" justify="end" align="middle">
            <Button
              variant={"outlined"}
              width={"300px"}
              onClick={() => handleAddItem(section.sectionId)}
            >
              {t('addItem')}
            </Button>
          </Div>
          <Div direction="column" gap="15px">
            {section.items.map((item, itemIndex) => (
              <TermsheetItem
                key={itemIndex}
                lang={lang}
                sectionId={section.sectionId}
                item={item}
              />
            ))}
          </Div>
        </Div>
      )
    }));
  };

  const panels = transformSectionsToPanels(filteredDocument?.sections);

  return (
    <>
      {loading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        <>
          <Card
            width="100%"
            height="auto"
            margin="28px 0px 50px 0"
            padding="28px 26px 40px 32px"
            shadow="5px 11px 26px #00000022"
          >
            <Row m="0 0 24px 0" justify="space-between">
              <Col>
                <Input
                  width="400px"
                  label={t("shareholder")}
                  value={filteredDocument?.shareholder || ''}
                  sizeLabel={theme.fonts.size.default}
                  onChange={handleChange}
                  id="shareholder"
                  name="shareholder"
                />
              </Col>
              <Col>
                <Row height="100%" justify="end" align="center">
                  <Button
                    icon={<img alt="change" src={languageIcon} />}
                    width={"250px"}
                    height={"35px"}
                    variant="outlined"
                    m="0 0 0 10px"
                    onClick={() => setLang((prevLang) => (prevLang === 'es' ? 'en' : 'es'))}
                  >
                    {lang === 'es' ? t("changeToEnglish") : t("changeToSpanish")}
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row m="0 0 24px 0">
              <TextArea
                width="100%"
                padding="10px"
                rows={6}
                label={t("introduction")}
                value={filteredDocument?.introduction}
                sizeLabel={theme.fonts.size.default}
                onChange={handleChange}
                id="introduction"
                name="introduction"
              />
            </Row>
          </Card>

          <Card
            width="100%"
            height="auto"
            margin="28px 0px 50px 0"
            padding="28px 26px 40px 32px"
            shadow="5px 11px 26px #00000022"
          >
            <Collapse
              activeKey={activeKey.toString()}
              handlePanelChange={(key) => { setActiveKey(key) }}
              panels={panels}
              group={true}
              marginBottom={"0px"}
              headerHeight="50px"
            />
            <Button width={"250px"} m="30px 0 0 0" onClick={handleSaveTermsheet} loading={saving}>
              {t("saveTermsheet")}
            </Button>
          </Card>
        </>
      )}

      {isDrawerAddItemOpen && (
        <DrawerEditItem
          visible={isDrawerAddItemOpen}
          item={null}
          handleClose={() => closeDrawerAddItemOpen()}
          handleSave={addItemToSection}
        />
      )}
    </>
  );
};

ContentTermsheetMaster.propTypes = {
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};