import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Table } from "../../../../components/Table";
import { theme } from "../../../../styles/theme";

const ModalAssessment = ({
    showModal,
    handleCloseModal,
}) => {

    return (
        <Modal open={showModal} onCancel={handleCloseModal}>
            <Modal.Header
                align="-webkit-center"
                padding={"22px 50px 0px 50px"}
                title={'Valoración del proyecto'}
                weightTitle={theme.fonts.weight.semibold}
            />
            <Modal.Body
                margin="28px 0 32px 0"
                align={"center"}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <Col width="100%" height="100%" align="center" justify="center">
                    <Row width="100%" m="0 0 32px 0">
                        <Table
                            data={[
                                { discountRate: "7%", assessment: "182.093" },
                                { discountRate: "8%", assessment: "129.945" },
                                { discountRate: "9%", assessment: "86.685" },
                            ]}
                            shadow={"0px"}
                            padding={"0px"}
                            columns={[
                                {
                                    title: "Tasa de descuento",
                                    dataIndex: "discountRate",
                                    key: "discountRate",
                                }, {
                                    title: "Equity Value (€/MWp)",
                                    dataIndex: "assessment",
                                    key: "assessment",
                                }]}
                            rowClassName={(record, index) =>
                                index % 2 === 0 ? "table-row-light" : "table-row-dark"
                            }
                            pagination={false}
                        />
                    </Row>
                </Col>
            </Modal.Body>
        </Modal>
    );
};

ModalAssessment.propTypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
};

export default ModalAssessment;
