import React, { useState } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Col, Row, Text } from "../../../styles/Common";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import DrawerEditItem from "./DrawerEditItem";
import useTermsheetStore from "../../../core/store/termsheet-store";
import { EditIcon } from "../../../assets/icons";
import dragIcon from "../../../assets/icons/drag.svg"

export const TermsheetItem = ({ lang, sectionId, item }) => {
  const { t } = useTranslation('termsheetMaster');
  const { editItem, swapItems } = useTermsheetStore();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  if(!item) return null;

  const handleEditClick = () => {
    setSelectedItem(item);
    setIsDrawerVisible(true);
  };

  const handleSaveItem = (updatedItem) => {
    editItem(lang, sectionId, updatedItem);
    setIsDrawerVisible(false);
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', item.order);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const draggedItemOrder = parseInt(event.dataTransfer.getData('text/plain'));
    const droppedItemOrder = item.order;
    
    if (draggedItemOrder >= 0 && droppedItemOrder >= 0) {
      swapItems(lang, sectionId, draggedItemOrder, droppedItemOrder);
    }
  };

  return (
    <>
      <Card
        draggable="true"
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        width="100%"
        height="auto"
        background={"#f5f8fa"}
        padding="28px 26px 40px 32px"
        shadow={null}
      >
        <Row m="0 0 20px 0">
          <Col width="60%">
            <Text size={theme.fonts.size.smi} color={theme.colors.green}>{t("title")}</Text>
            <Text>{item.title}</Text>
          </Col>
          <Col width="30%">
            <Text size={theme.fonts.size.smi} color={theme.colors.green}>{t("visibility")}</Text>
            <Text>{t(item.visibility)}</Text>
          </Col>
          <Col width="10%">
            <Row height="100%" align="center" justify="center"  gap="15px">
              <EditIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#50B6BE'}} onClick={handleEditClick}/>
              <img width="30px" height="30px" alt="drag" src={dragIcon} style={{ cursor: 'grab'}}/>
            </Row>
          </Col>
        </Row>

        <hr
          style={{
            width: "99%",
            border: `1px solid ${theme.colors.gray100}`,
            opacity: 1,
            margin: '0 0 20px 0',
            alignSelf: "center",
          }}
        />

        <Row>
          <Col>
            <Text size={theme.fonts.size.smi} color={theme.colors.green}>
              {t("value")}
            </Text>
            <Text>
              {item.value.split(/\[([^\]]+)\]/g).map((part, index) =>
                index % 2 === 1 ? (
                  <span key={index} style={{ color: theme.colors.green, fontWeight: "500" }}>
                    [{part}]
                  </span>
                ) : (
                  part
                )
              )}
            </Text>
          </Col>
        </Row>
      </Card>

      {isDrawerVisible && (
        <DrawerEditItem
          visible={isDrawerVisible}
          item={selectedItem}
          handleClose={() => setIsDrawerVisible(false)}
          handleSave={handleSaveItem}
        />
      )}
    </>
  );
};

TermsheetItem.propTypes = {
  item: PropTypes.object,
  lang: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
};