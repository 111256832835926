import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Text } from '../../../styles/Common';
import { theme } from '../../../styles/theme';
import { Button } from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import { Input } from '../../../components/Input';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../../../components/TextArea';
import { Radio } from '../../../components/Radio';
import DrawerVariables from "./DrawerVariables";

const DrawerEditItem = ({ visible, item, handleClose, handleSave }) => {
  const { t } = useTranslation('termsheetMaster');
  const [editedItem, setEditedItem] = useState(item ? item : { id: null, title: "", visibility: "both", value: "" });
  const [isDrawerVariablesOpen, setIsDrawerVariablesOpen] = useState(false);
  useEffect(() => {
    setEditedItem(item);
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    handleSave(editedItem);
  };

  if (!visible) return null;

  return (
    <Drawer
      title={item ? t("editItemTitle") : t("addItemTitle")}
      open={visible}
      onClose={handleClose}
      width="500px"
      padding="0 20px 20px 20px"
    >
      <Col>
        <Row height="100%" justify="end" align="center">
          <Button
            height={"35px"}
            width={"110px"}
            m="0 0 0 10px"
            variant="outlined"
            onClick={() => setIsDrawerVariablesOpen(true)}
          >
            {t("variables")}
          </Button>
        </Row>
      </Col>
      <Row m="20px 10px 10px 10px">
        <Col width="100%">
          <Input
            width="100%"
            label={t("title")}
            value={editedItem?.title}
            sizeLabel={theme.fonts.size.default}
            onChange={handleChange}
            id="title"
            name="title"
          />
        </Col>
      </Row>
      <Row m="10px 10px 20px 10px">
        <Col width="100%">
          <Text mb="5px" size={theme.fonts.size.default} color={theme.colors.gray200}>{t("visibility")}</Text>
          <Radio.Group
            name="visibility"
            gap="15px"
            value={editedItem?.visibility}
            onChange={handleChange}
          >
            <Radio label={t("both")} value={"both"}></Radio>
            <Radio label={t("developer")} value={"developer"}></Radio>
            <Radio label={t("investor")} value={"investor"}></Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row m="10px 10px 20px 10px">
        <Col width="100%">
          <TextArea
            width="100%"
            padding="10px"
            rows={10}
            label={t("value")}
            value={editedItem?.value}
            sizeLabel={theme.fonts.size.default}
            onChange={handleChange}
            id="value"
            name="value"
          />
        </Col>
      </Row>
      <Row m="10px 10px 20px 10px">
        <Button width="100%" onClick={handleSaveClick}>{t("saveItem")}</Button>
      </Row>
      {isDrawerVariablesOpen && (
        <DrawerVariables
          visible={isDrawerVariablesOpen}
          handleClose={() => setIsDrawerVariablesOpen(false)}
        />
      )}
    </Drawer>
  );
};

DrawerEditItem.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default DrawerEditItem;