import React, { useEffect, useState } from "react";
import { ModuleContainer } from "../../styles/Common";
import Tabs from "../../components/Tabs";
import toast from "react-hot-toast";
import { ContentTermsheetMaster } from "./components/contentTermsheetMaster";
import useTermsheetStore from "../../core/store/termsheet-store";
import { useTranslation } from "react-i18next";
import useGetTermsheetMaster from "./hooks/useGetTermsheetMaster";
import useUpdateTermsheetMaster from "./hooks/useUpdateTermsheetMaster";

export const TermsheetMaster = () => {
  const { t } = useTranslation('termsheetMaster');
  const { strategyType, setStrategyType, setId, setDocuments } = useTermsheetStore();
  const [activeKey, setActiveKey] = useState("1");
  const [saving, setSaving] = useState(false);
  const termsheetInfo = useGetTermsheetMaster(strategyType);
  const { isSuccess, isLoading, data, refetch } = termsheetInfo;

  const updateTermsheet = useUpdateTermsheetMaster();

  useEffect(() => {
    if (isSuccess && data) {
      setDocuments(data.data.documents);
      setId(data.data._id);
    }
  }, [data]);

  useEffect(() => {
    if (strategyType) {
      termsheetInfo.refetch();
    }
  }, [strategyType]);

  const onChange = (key) => {
    setActiveKey(key);
    setStrategyType(key === "1" ? "senior-debt" : "junior-debt");
  };

  const saveTermsheet = (id, body) => {
    setSaving(true);
    updateTermsheet.reset();
    updateTermsheet.mutate(
      { id: id, data: body },
      {
        onSuccess: () => {
          toast.success(t("termsheetSaved"));
          refetch();
        },
        onError: (err) => {
          toast.error(t("termsheetNotSaved"));
        },
        onSettled: () => {
          setSaving(false);
        },
      }
    );
  }

  const panels = [
    {
      key: "1",
      tab: t("senior"),
      type: 2,
      children: (
        <ContentTermsheetMaster 
          loading={isLoading}
          saving={saving}
          onSave={saveTermsheet}
        />
      ),
    },
    {
      key: "2",
      tab: t("junior"),
      type: 1,
      children: (
        <ContentTermsheetMaster 
          loading={isLoading}
          saving={saving}
          onSave={saveTermsheet}
        />
      ),
    },
  ];

  return (
    <ModuleContainer padding="35px 69px 0 32px" direction="column">
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {panels.map((panel) => (
          <Tabs.Panel tab={panel.tab} key={panel.key}>
            {panel.children}
          </Tabs.Panel>
        ))}
      </Tabs>
    </ModuleContainer>
  );
};