import { create } from "zustand";

/* eslint-disable no-undef */

/**
 * @typedef {'senior-debt' | 'junior-debt'} StrategyType
 * @typedef {'en' | 'es'} Lang
 * @typedef {'both' | 'investor' | 'developer'} ItemVisibility
 */

/**
 * @typedef {Object} Document
 * @property {Lang} lang
 * @property {string} shareholder
 * @property {string} introduction
 * @property {Section[]} sections
 */

/**
 * @typedef {Object} Section
 * @property {string} sectionId
 * @property {string} title
 * @property {Item[]} items
 */

/**
 * @typedef {Object} Item
 * @property {string} itemId
 * @property {number} order
 * @property {ItemVisibility} visibility
 * @property {string} name
 * @property {string} value
 */

/**
 * @typedef {Object} Termsheet
 * @property {string} _id
 * @property {StrategyType} strategyType
 * @property {Document[]} documents
 */

/**
 * @typedef {Object} TermsheetStore
 * @property {string} _id
 * @property {StrategyType} strategyType
 * @property {Document[]} documents
 * @property {() => Termsheet} getTermsheetMaster
 * @property {() => Document[]} getDocuments
 * @property {(lang: Lang, sectionId: string) => Section} getSection
 * @property {(id: string) => void} setId
 * @property {(documents: Document[]) => void} setDocuments
 * @property {(type: StrategyType) => void} setStrategyType
 * @property {(lang: Lang, updatedDocument: Partial<Document>) => void} editDocument
 * @property {(lang: Lang, sectionId: string, item: Item) => void} addItem
 * @property {(lang: Lang, sectionId: string, updatedItem: Partial<Item>) => void} editItem
 * @property {(lang: Lang, sectionId: string, orderFor: number, orderTo: number) => void} swapItems
 */

const useTermsheetStore = create((set, get) => ({
  _id: null,
  strategyType: 'senior-debt',
  documents: [],

  getTermsheetMaster: () => {
    return {
      strategyType: get().strategyType,
      documents: get().documents
    }
  },

  getDocuments: () => {
    return get().documents;
  },

  getId: () => {
    return get()._id;
  },

  setId: (id) => {
    set({ _id: id });
  },

  setDocuments: (documents) => {
    set({ documents });
  },

  getSection: (lang, sectionId) => {
    const document = get().documents.find(doc => doc.lang === lang);
    return document.sections.find(section => section.sectionId === sectionId);
  },
  
  editDocument: (lang, updatedDocument) => {
    set((state) => ({
      documents: state.documents.map(doc => 
        doc.lang === lang ? { ...doc, ...updatedDocument } : doc
      )
    }));
  },

  setStrategyType: (type) => {
    set({ strategyType: type });
  },

  addItem: (lang, sectionId, item) => {
    set((state) => ({
      documents: state.documents.map(doc => 
        doc.lang === lang ? {
          ...doc,
          sections: doc.sections.map(section => 
            section.sectionId === sectionId ? {
              ...section,
              items: [
                ...section.items,
                { ...item, itemId: null, order: section.items.length }
              ]
            } : section
          )
        } : doc
      )
    }));
  },

  editItem: (lang, sectionId, updatedItem) => {
    set((state) => ({
      documents: state.documents.map(doc => 
        doc.lang === lang ? {
          ...doc,
          sections: doc.sections.map(section => 
            section.sectionId === sectionId ? {
              ...section,
              items: section.items.map(item => 
                item.itemId === updatedItem.itemId ? { ...item, ...updatedItem } : item
              )
            } : section
          )
        } : doc
      )
    }));
  },

  swapItems: (lang, sectionId, orderFor, orderTo) => {
    set((state) => ({
      documents: state.documents.map(doc => 
        doc.lang === lang ? {
          ...doc,
          sections: doc.sections.map(section => 
            section.sectionId === sectionId ? {
              ...section,
              items: section.items.map(item => {
                if (item.order === orderFor) {
                  const itemToSwap = section.items.find(i => i.order === orderTo);
                  return { ...itemToSwap, order: item.order };
                } else if (item.order === orderTo) {
                  const itemToSwap = section.items.find(i => i.order === orderFor);
                  return { ...itemToSwap, order: item.order };
                }
                return item;
              })
            } : section
          )
        } : doc
      )
    }));
  }
}));

export default useTermsheetStore;