import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { ModuleContainer } from "../../styles/Common";
import { Card } from "../../components/Card";
import { Row, Text, Div } from "../../styles/Common";
import {
  ArrowLeftIcon,
  WindyFillIcon,
  SunIcon,
  DownloadIcon,
} from "../../assets/icons";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import { SummaryProject } from "./components/SummaryProject";
import { InfoDetailInvestor } from "./components/InfoDetailInvestor";
import { DocumentationInvestor } from "./components/DocumentationInvestor";
import { useTranslation } from "react-i18next";
import useGetProjectInfo from "../../core/hooks/useGetProject";
import { ProjectQuestions } from "./components/Questions/ProjectQuestions";
import useAcceptTyC from "./hooks/useAcceptTyC";
import ModalTerms from "./components/ModalTerms";
import { IndicativeTermSheet } from "./components/DebtProject/IndicativeTermsheet";
import { ExecutionDocs } from "./components/DebtProject/ExecutionDocs";
import { FinancialDashboard } from "./components/DebtProject/FinancialDashboard";
import { DrawerIndicativeOffer } from "./components/DrawerIndicativeOffer";
import {
  dateFormat,
  downloadAndOpenFile,
  downloadFile,
  FinancialModelFields,
  formatNumber,
  formatToThousand,
  getToken,
  ReturnYearLabel,
  unformatNumber,
} from "../../utilities/helpers";
import useSetActivityLog from "./hooks/useSetActivityLog";
import useGetFinancialModel from "./hooks/useGetFinancialModel";
import Tabs from "../../components/Tabs";
import useGetInvestorAcceptance from "./hooks/useGetInvestorAceptance";
import { axiosClient } from "../../core/services/axiosInstance";
import ModalDeepeningInvestment from "./components/DebtProject/ModalInvestment";
import useSendNda from "./hooks/useSendNda";
import useContactAlterTeam from "./hooks/useContactAlterTeam";

export const InvestorProjectDetail = () => {
  const { t, i18n } = useTranslation(["investorProjectDetail"]);
  const locale = i18n.language;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sendNda = useSendNda();
  const contactAlterTeam = useContactAlterTeam();
  const setLog = useSetActivityLog();
  const acceptTerm = useAcceptTyC();

  const { search } = useLocation();

  const projectId = new URLSearchParams(search).get("projectId");

  const { data, isSuccess } = useGetFinancialModel(projectId);
  const [dashboardInfo, setDashboardInfo] = useState(FinancialModelFields);

  const { data: dataAcceptance } = useGetInvestorAcceptance(projectId);

  const [showModal, setShowModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);

  const projectDataInfo = useGetProjectInfo(projectId);
  const {
    data: dataProject,
    isSuccess: isSuccessDataProject,
    isLoading: isLoadingDataProject,
  } = projectDataInfo;
  const [project, setProject] = useState();
  const [debt, setDebt] = useState(true); // Estado para debt
  const [hasModel, setHasModel] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setDebt(project?.debt);
  }, [project?.debt]);

  useEffect(() => {
    if (isSuccess) {
      if (data.data.length === 0) {
        setHasModel(false);
      } else {
        setHasModel(true);
        setDashboardInfo({ ...FinancialModelFields, ...data?.data[0] });
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessDataProject && dataProject) {
      const info = dataProject?.data?.result?.data;

      setProject({
        id: info?._id,
        name: info?.teaserName,
        projectType: info?.projectType?.type?.name,
        financingType: info?.projectType?.kind?.name,
        objective: info?.projectType?.instance.name,
        projectTotalMW: info?.projectTotalMW,
        plants: info?.plants,
        sponsor: info?.sponsor,
        debt: info?.projectType?.instance?.name === "financing",
        teaserProjectSize: info?.teaserProjectSize,
        teaserProjectStatus: info?.teaserProjectStatus,
        teaserTargetPrice: info?.teaserTargetPrice,
        teasercloseDate: info?.teasercloseDate,
        isTermSheet: info?.isTermSheet,
        teaserId: info?.teaserId,
        teaserInvestment: info?.teaserInvestment,
        teaserTargetTenor: info?.teaserTargetTenor,
      });
    }
  }, [dataProject, isSuccessDataProject]);

  const handleBack = () => {
    navigate("/projects");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleTerms = () => {
    const formData = {
      projectId: projectId,
      isActive: true,
    };
    acceptTerm.reset();
    acceptTerm.mutate(formData, {
      onSuccess: () => {
        setShowModal(false);
        queryClient.invalidateQueries("getTermsAndConditions");
        toast.success("Términos y condiciones enviados por correo electrónico");
      },
      onError: (err) => {
        err.response.data.result.code.message.map((error) =>
          toast.error(t(`:${error}`))
        );
      },
    });
  };

  const handleIndicativeOffer = () => {
    setOpenDrawer(true);
    const payload = {
      action: "access-indicative-offer",
      projectId: projectId,
    };

    setLog.reset();
    setLog.mutate(payload);
  };

  const handleDeepenInvestment = () => {
    setShowInvestmentModal(true);
    const payload = {
      action: "access-deepening-investment",
      projectId: projectId,
    };

    setLog.reset();
    setLog.mutate(payload);
  };

  const onChange = (key) => {
    if (debt && key === "5") {
      const payload = {
        action: "access-data-room",
        projectId: projectId,
      };

      setLog.reset();
      setLog.mutate(payload);
      setActiveKey(key);
    }

    if ((debt && key === "4") || (!debt && key === "3")) {
      const payload = {
        action: "access-qa",
        projectId: projectId,
      };

      setLog.reset();
      setLog.mutate(payload);
      setActiveKey(key);
    }

    if ((!debt && key === "4") || (debt && key === "5")) {
      if (!dataAcceptance?.data?.accepted) {
        setShowModal(true);
        setActiveKey(activeKey);
      } else {
        const payload = {
          action: "access-data-room",
          projectId: projectId,
        };

        setLog.reset();
        setLog.mutate(payload);
        setActiveKey(key);
      }
    } else {
      setActiveKey(key);
    }
  };

  const panelsDebt = [
    {
      key: "1",
      tab: t("summary"),
      children: (
        <SummaryProject
          projectInfo={project}
          debt={debt}
          termsApproved={dataAcceptance?.data?.accepted}
          setShowModal={setShowModal}
          dashboardInfo={dashboardInfo}
          hasModel={hasModel}
        />
      ),
      permission: true,
    },
    {
      key: "2",
      tab: t("indicativeTermSheet"),
      children: <IndicativeTermSheet projectId={projectId} activeKey={activeKey} />,
      permission: project?.isTermSheet,
    },
    {
      key: "3",
      tab: t("financialDashboard"),
      children: (
        <FinancialDashboard
          dashboardInfo={dashboardInfo}
          projectId={projectId}
        />
      ),
      permission: hasModel,
    },
    {
      key: "4",
      tab: "Q&A",
      children: <ProjectQuestions projectId={projectId} />,
      permission: true,
    },
    {
      key: "5",
      tab: t("documentation"),
      children: <DocumentationInvestor projectId={projectId} />,
      permission: true,
    },
    {
      key: "6",
      tab: t("executionDocs"),
      children: <ExecutionDocs projectId={projectId} />,
      permission: false,
    },
  ];

  const panelsSale = [
    {
      key: "1",
      tab: t("summary"),
      children: (
        <SummaryProject
          projectInfo={project}
          debt={debt}
          termsApproved={dataAcceptance?.data?.accepted}
          setShowModal={setShowModal}
        />
      ),
      permission: true,
    },
    {
      key: "2",
      tab: t("information"),
      children: (
        <InfoDetailInvestor
          projectInfo={project}
          termsApproved={dataAcceptance?.data?.accepted}
          setShowModal={setShowModal}
        />
      ),
      permission: true,
    },
    {
      key: "3",
      tab: "Q&A",
      children: <ProjectQuestions projectId={projectId} />,
      permission: true,
    },
    {
      key: "4",
      tab: t("documentation"),
      children: <DocumentationInvestor projectId={projectId} />,
      permission: true,
    },
  ];

  const handleDownloadTeaser = () => {
    const strategyId = debt ?
      project?.teaserId?.strategiesFinancing[0].transactionSummary?.strategyId
      : project?.teaserId?.strategiesSale[0].transactionSummary?.strategyId;
    downloadAndOpenFile(
      `teaser/${project?.teaserId?._id}/pdf/${strategyId}?lang=${project.teaserId?.projectSummary[0]?.language}`,
      "Teaser",
      axiosClient,
      getToken()
    );
  };

  const handleDownloadTermsheet = () => {
    downloadFile(
      `documentation/download/${projectId}/6`,
      "Termsheet",
      axiosClient,
      getToken()
    );
  };

  const handleRequestNDA = () => {
    sendNda.reset();
    sendNda.mutate(projectId, {
      onSuccess: () => {
        toast.success(t("deepeningInvestmentModal.success"));
        setShowInvestmentModal(false);
      },
      onError: (err) => {
        err.response.data.result.code.message.map((error) =>
          toast.error(t(`:${error}`))
        );
      },
    })
  };

  const handleContact = () => {
    contactAlterTeam.reset();
    contactAlterTeam.mutate(projectId, {
      onSuccess: () => {
        toast.success(t("deepeningInvestmentModal.success"));
        setShowInvestmentModal(false);
      },
      onError: (err) => {
        err.response.data.result.code.message.map((error) =>
          toast.error(t(`:${error}`))
        );
      },
    })
  };

  if (isLoadingDataProject) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  const valueTypes = ["", "-", "#######", null, undefined, "NaN"];

  const formatValue = (value) => {
    if (valueTypes.includes(value)) {
      return "-";
    }
    return formatNumber(unformatNumber(value), locale);
  };

  const interestRate = dashboardInfo?.fixed_variable !== "" && dashboardInfo?.interest
    ? dashboardInfo?.fixed_variable === "Variable"
      ? `${t('euribor')} + ${formatValue(dashboardInfo?.interest)}%`
      : `${t('fixed')} (${formatValue(dashboardInfo?.interest)}%)`
    : "-"

  const panels = debt ? panelsDebt : panelsSale;

  let headerTextPrice = null
  if (debt) {
    if (dashboardInfo?.debt_installed_capacity > 0) {
      headerTextPrice = `${t("targetDebt")} € ${formatNumber(
        unformatNumber(dashboardInfo?.debt_installed_capacity || ""),
        locale
      ) || "-"
        }`
    }
  } else {
    if (project?.teaserTargetPrice && project?.teaserTargetPrice > 0) {
      headerTextPrice = `${t("price")}: € ${formatNumber(
        unformatNumber(project?.teaserTargetPrice || ""),
        locale
      ) || t("openPrice")
        }`
    } else {
      headerTextPrice = `${t("price")}: ${formatToThousand(project?.teaserId?.strategiesSale[0]?.priceConsiderations?.pricePerMWpFrom, locale, 1)} - ${formatToThousand(project?.teaserId?.strategiesSale[0]?.priceConsiderations?.pricePerMWpTo, locale, 1)}`
    }
  }

  return (
    <ModuleContainer direction="column" padding="32px 69px 0px 32px">
      <DrawerIndicativeOffer
        openDrawer={openDrawer}
        handleClose={handleCloseDrawer}
        projectId={projectId}
      />
      <ModalTerms
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleTerms={handleTerms}
      />
      <ModalDeepeningInvestment
        showModal={showInvestmentModal}
        handleCloseModal={() => setShowInvestmentModal(false)}
        handleRequestNDA={handleRequestNDA}
        handleContact={handleContact}
        loadingNda={sendNda.isLoading}
        loadingContact={contactAlterTeam.isLoading}
      />
      <Card
        width="100%"
        height="216px"
        background={theme.colors.blue}
        padding="31px 29px 28px 32px"
        margin="0 0 18px 0"
      >
        <Row
          align="center"
          m="0 0 12px 0"
          style={{ cursor: "pointer" }}
          gap="6px"
          onClick={handleBack}
        >
          <ArrowLeftIcon />
          <Text weight={theme.fonts.weight.medium} color={theme.colors.green}>
            {t("goBack")}
          </Text>
        </Row>
        <Row align="center" justify="space-between" m="0 0 4px 0">
          <Text
            weight={theme.fonts.weight.medium}
            color={theme.colors.white}
            size={theme.fonts.size.h4}
          >
            {project?.name}
          </Text>
        </Row>
        <Row m="0 0 12px 0"></Row>
        <Row justify="space-between" m="24px 0 0 0">
          <Div
            width="auto"
            p="8px 12px"
          >
            {project?.projectType === "solar" ? (
              <SunIcon fill={theme.colors.white} width="24px" height="24px" />
            ) : (
              <WindyFillIcon
                fill={theme.colors.white}
                width="24px"
                height="24px"
              />
            )}
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {t(project?.projectType)}
            </Text>
          </Div>
          <Div
            width="auto"
            p="8px 12px"
          >
            <Text color={theme.colors.white}>|</Text>
          </Div>
          <Div
            width="auto"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {" "}
              {`${t("size")}: ${formatNumber(
                unformatNumber(project?.teaserProjectSize || ""),
                locale
              ) || "-"
                } MWp`}
            </Text>
          </Div>
          {headerTextPrice && (
            <>
              <Div
                width="auto"
                p="8px 12px"
              >
                <Text color={theme.colors.white}>|</Text>
              </Div>
              <Div
                width="auto"
                p="8px 12px"
              >
                <Text color={theme.colors.white} size={theme.fonts.size.sm}>
                  {" "}
                  {headerTextPrice}
                </Text>
              </Div>
            </>
          )}
          {project?.teaserCloseDate && (<>
            <Div
              width="auto"
              p="8px 12px"
            >
              <Text color={theme.colors.white}>|</Text>
            </Div>
            <Div
              width="auto"
              p="8px 12px"
            >
              <Text color={theme.colors.white} size={theme.fonts.size.sm}>
                {" "}
                {`${t("closing")} ${project?.teaserCloseDate
                  ? dateFormat(project?.teaserCloseDate)
                  : "-"
                  }`}
              </Text>
            </Div>
          </>)}

          <Div
            width="auto"
            p="8px 12px"
          >
            <Text color={theme.colors.white}>|</Text>
          </Div>
          <Div
            width="auto"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {debt
                ? `${t("term")}: ${formatValue(project?.teaserTargetTenor)
                } ${ReturnYearLabel(
                  project?.teaserTargetTenor,
                  t("year"),
                  t("years")
                )}`
                : `${t("phase")} ${project?.teaserProjectStatus || "-"}`}
            </Text>
          </Div>
          {debt && (
              <>
                <Div
                  width="auto"
                  p="8px 12px"
                >
                  <Text color={theme.colors.white}>|</Text>
                </Div>
                <Div
                  width="auto"
                  p="8px 12px"
                >
                  <Text color={theme.colors.white} size={theme.fonts.size.sm}>
                    {`${t("investmentAmount")}: € ${project?.teaserId?.strategiesFinancing[0]?.anexoTwo?.seniorDebt ? formatNumber(project?.teaserId?.strategiesFinancing[0]?.anexoTwo?.seniorDebt, locale) : '-'} `}
                  </Text>
                </Div>
              </>
            )
          }
          {debt && (
            <>
              <Div
                width="auto"

                p="8px 12px"
              >
                <Text color={theme.colors.white}>|</Text>
              </Div>
              <Div
                width="auto"
                p="8px 12px"
              >
                <Text color={theme.colors.white} size={theme.fonts.size.sm}>
                  {`${t("profitability")}: ${hasModel ? interestRate : '-'}`}
                </Text>
              </Div>
            </>
          )}
        </Row>
      </Card>
      <Div width="100%" align="center" justify="end" gap="16px">
        {project?.teaserId?.isPublish && (
          <Button
            width="186px"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
            onClick={handleDownloadTeaser}
          >
            <DownloadIcon fill={theme.colors.white} />
            {t("downloadTeaser")}
          </Button>
        )}
        {debt && project?.isTermSheet && (
          <Button
            width="220px"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
            onClick={handleDownloadTermsheet}
          >
            <DownloadIcon fill={theme.colors.white} />
            {t("downloadTermsheet")}
          </Button>
        )}
        {debt ? (
          <Div>
            <Button
              width="222px"
              background={theme.colors.blue}
              hBackground={theme.colors.blue}
              onClick={handleDeepenInvestment}
            >
              {t("deepenInvestment")}
            </Button>
          </Div>
        ) : (
          <Button
            width="222px"
            background={theme.colors.blue}
            hBackground={theme.colors.blue}
            onClick={handleIndicativeOffer}
          >
            {t("submit")}
          </Button>
        )}
      </Div>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {panels.map(
          (panel) =>
            panel.permission && (
              <Tabs.Panel tab={panel.tab} key={panel.key}>
                {panel.children}
              </Tabs.Panel>
            )
        )}
      </Tabs>
      <Text
        size={theme.fonts.size.sm}
        color={theme.colors.gray300}
        align="center"
        mt="34px"
        mb="12px"
      >
        {t("alter")}
      </Text>
    </ModuleContainer>
  );
};
