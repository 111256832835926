import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import mwp from "../../../assets/icons/mwp.svg";
import years from "../../../assets/icons/years.svg";
import anual from "../../../assets/icons/anual.svg";
import ha from "../../../assets/icons/ha.svg";
import m from "../../../assets/icons/m.svg";
import kv from "../../../assets/icons/kv.svg";
import e from "../../../assets/icons/euro.svg";
import { Button } from "../../../components/Button";
import { FinancingConstruction } from "./financingConstruction";
import { FinancingRefinancing } from "./financingRefinancing";
import { SalePre } from "./salePre";
import { SaleOperation } from "./saleOperation";
import { unformatNumberOpex } from "../../../utilities/helpers";
import { theme } from "../../../styles/theme";
import { CommonFields } from "./commonFields";

export const PlantForm = ({
  financingType,
  projectType,
  handleSave,
  plantsInfo,
  setPlantsInfo,
  objective,
  isNewProject,
  formError,
  setFormError,
  provincesOptions,
  municipalityOptions,
  plantId,
  incidencesList,
}) => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;
  const navigate = useNavigate();
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");
  const projectName = new URLSearchParams(search).get("projectName");
  const [notSave, setNotSave] = useState(false);

  useEffect(() => {
    if (projectId === "null") {
      setNotSave(true);
    }
  }, [projectId]);

  const handleCancel = () => {
    if (isNewProject === "false") {
      navigate(
        `/projects/edit-project?projectId=${projectId}&selected=plants&projectName=${projectName}`
      );
    } else {
      navigate(
        `/projects/new-project?objective=${objective}&projectType=${projectType}&financingType=${financingType}&selected=plants&projectId=${projectId}`
      );
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newPlantsInfo = { ...plantsInfo };

    if (id === "name") {
      const newErrorForm = formError;
      newErrorForm[id].error = false;
      newErrorForm[id].message = "";
      setFormError(newErrorForm);
    }

    newPlantsInfo[id] = value;
    setPlantsInfo(newPlantsInfo);
  };

  
  const handleChangeFormatOpex = (event) => {
    const { id, value } = event.target;
    const newPlantsInfo = { ...plantsInfo };

    newPlantsInfo[id] = unformatNumberOpex(value, locale).replace(/[^\d.,]/g, "");
    setPlantsInfo(newPlantsInfo);
  };

  const handleDeleteError = (event) => {
    const { id } = event.target;

    const newErrorForm = formError;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setFormError(newErrorForm);
  };

  const handleSelect = (value, id) => {
    const newPlantsInfo = { ...plantsInfo };

    newPlantsInfo[id] = value;
    setPlantsInfo(newPlantsInfo);
  };

  const handleDate = (value, id) => {
    const newPlantsInfo = { ...plantsInfo };

    newPlantsInfo[id] = value;
    setPlantsInfo(newPlantsInfo);
  };

  const handleRadio = (event) => {
    const { value, name } = event.target;
    const newPlantsInfo = { ...plantsInfo };

    newPlantsInfo[name] = value;
    setPlantsInfo(newPlantsInfo);
  };

  return (
    <>
      <Card
        width="100%"
        height="min-content"
        padding={"28px 36.5px 49px 38.5px"}
      >
        <Text
          color={theme.colors.blue}
          size={theme.fonts.size.h6}
          weight={theme.fonts.weight.medium}
          mb="23.5px"
        >
          {t("plantInformation")}
        </Text>
        <hr
          style={{
            width: "100%",
            border: `1px solid ${theme.colors.gray100}`,
            marginBottom: "36.5px",
          }}
        />
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          objective={objective}
          plantsInfo={plantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          formError={formError}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
          setPlantsInfo={setPlantsInfo}
          handleDeleteError={handleDeleteError}
          handleDate={handleDate}
          plantId={plantId}
          incidencesList={incidencesList}
        />
        {financingType === "construction" && (
          <FinancingConstruction
            plantsInfo={plantsInfo}
            m={m}
            kv={kv}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            setPlantsInfo={setPlantsInfo}
          />
        )}
        {financingType === "financed" && (
          <FinancingRefinancing
            plantsInfo={plantsInfo}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            setPlantsInfo={setPlantsInfo}
          />
        )}
        {financingType === "RtBCOD" && (
          <SalePre
            plantsInfo={plantsInfo}
            m={m}
            kv={kv}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            setPlantsInfo={setPlantsInfo}
          />
        )}
        {financingType === "operation" && (
          <SaleOperation
            plantsInfo={plantsInfo}
            e={e}
            m={m}
            kv={kv}
            handleSelect={handleSelect}
            setPlantsInfo={setPlantsInfo}
            handleDate={handleDate}
            handleChangeFormatOpex={handleChangeFormatOpex}
          />
        )}
      </Card>
      <Div m="60px 0 0 0" gap="10px">
        <Button variant={"outlined"} onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSave} disabled={notSave}>
          {plantId ? t("updatePlant") : t("createPlant")}
        </Button>
      </Div>
    </>
  );
};

PlantForm.propTypes = {
  financingType: PropTypes.string,
  projectType: PropTypes.string,
  handleSave: PropTypes.func,
  plantId: PropTypes.string,
  plantsInfo: PropTypes.object,
  setPlantsInfo: PropTypes.func,
  objective: PropTypes.string,
  isNewProject: PropTypes.bool,
  formError: PropTypes.object,
  setFormError: PropTypes.func,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
  incidencesList: PropTypes.array,
};
