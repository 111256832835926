import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Div, Text, Row, Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import useGetScraperById from "../hooks/useGetScraperById";
import useUpdateScraper from "../hooks/useUpdateScraper";

export const DrawerEditInfo = ({
  openDrawer,
  handleClose,
  selectedScraper,
  editInfoErrorForm,
  setEditInfoErrorForm,
  companiesOptions,
}) => {
  const { t } = useTranslation("scraper");
  const updateScraper = useUpdateScraper();
  const queryClient = useQueryClient();

  const [forceUpdate, setForceUpdate] = useState(false);

  const getScraper = useGetScraperById(selectedScraper?.id);
  const { data } = getScraper;
  const [form, setForm] = useState({
    name: "",
    promoterCompany: "",
    peakPower: null,
    SPVName: "",
    motherCompany: null,
  });

  useEffect(() => {
    if (data?.data?.data) {
      setForm({
        name: data?.data?.data.nombreProyecto,
        peakPower: data?.data?.data.potenciaPico,
        SPVName: data?.data?.data.nombreSPV,
        promoterCompany: data?.data?.data.companiaPromotora,
        motherCompany: data?.data?.data.empresaMatriz
          ? data?.data?.data.empresaMatriz
          : null,
      });
    }
  }, [data]);

  // const handleSelect = (value, id) => {
  //   const newErrorForm = { ...editInfoErrorForm };
  //   newErrorForm[id].error = false;
  //   newErrorForm[id].message = "";
  //   setEditInfoErrorForm(newErrorForm);

  //   const newForm = { ...form };
  //   newForm[id] = value;
  //   setForm(newForm);
  // };

  const handleCompanySelect = (value, id) => {
    const newErrorForm = { ...editInfoErrorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setEditInfoErrorForm(newErrorForm);

    const company = companiesOptions.find((x) => x.value === value);

    const newForm = { ...form };
    newForm[id] = value;
    newForm.SPVName = company.label;
    setForm(newForm);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;
    const newErrorForm = { ...editInfoErrorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setEditInfoErrorForm(newErrorForm);

    const newForm = { ...form };
    if (id === "SPVName" || id === "name") {
      newForm[id] = value;
    } else {
      newForm[id] = Number(value);
    }
    setForm(newForm);
  };

  const handleSave = () => {
    const formData = {
      projectName: form.name,
      promoterCompany: form.promoterCompany,
      nameSPV: form.SPVName,
      matrizCompany: form.motherCompany,
      peakPower: form.peakPower,
    };
    updateScraper.reset();
    updateScraper.mutate(
      { scraperId: data?.data?.data._id, formData: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getScraper");
          toast.success(t(`success`));
          handleClose();
        },
        onError: (err) => {
          toast.error(err.response.data.result.code.message);
        },
      }
    );

    setForceUpdate(!forceUpdate);
  };

  return (
    <Drawer
      title={t("editInformation")}
      open={openDrawer}
      onClose={handleClose}
      width="430px"
      padding="0px 31px 40px 28px"
    >
      <Row m="24px 0 32px 0">
        <Text color={theme.colors.gray500}>{t("updateInfo")}</Text>
      </Row>
      <Col style={{ gap: "31px" }}>
        <Input
          label={t("nameProject")}
          id="name"
          sizeLabel={theme.fonts.size.default}
          value={form?.name}
          onChange={handleChange}
          error={editInfoErrorForm.name.error}
          helper={
            editInfoErrorForm.name.error ? editInfoErrorForm.name.message : ""
          }
        />
        <Input
          label={t("peakPower")}
          id="peakPower"
          type="number"
          sizeLabel={theme.fonts.size.default}
          suffix="MWp"
          value={form?.peakPower}
          onChange={handleChange}
          error={editInfoErrorForm.peakPower.error}
          helper={
            editInfoErrorForm.peakPower.error
              ? editInfoErrorForm.peakPower.message
              : ""
          }
        />
        <Input
          label={t("nameSpv")}
          id="promoterCompany"
          sizeLabel={theme.fonts.size.default}
          value={form?.promoterCompany}
          onChange={handleCompanySelect}
          error={editInfoErrorForm.promoterCompany.error}
          helper={
            editInfoErrorForm.promoterCompany.error
              ? editInfoErrorForm.promoterCompany.message
              : ""
          }
        />
        <Select
          label={t("parentCompany")}
          sizeLabel={theme.fonts.size.default}
          value={form.motherCompany}
          onChange={(event) => handleCompanySelect(event, "motherCompany")}
          error={editInfoErrorForm.motherCompany.error}
          helper={
            editInfoErrorForm.motherCompany.error
              ? editInfoErrorForm.motherCompany.message
              : ""
          }
          options={companiesOptions}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        />
      </Col>
      <Div justify="center" align="end" height="100%" m="31px 0 0 0">
        <Button
          width="115px"
          onClick={handleSave}
          loading={updateScraper.isLoading}
        >
          {t("save")}
        </Button>
      </Div>
    </Drawer>
  );
};

DrawerEditInfo.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedScraper: PropTypes.object,
  editInfoErrorForm: PropTypes.object,
  setEditInfoErrorForm: PropTypes.func,
  companiesOptions: PropTypes.array,
};
